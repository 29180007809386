// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginForm from './components/Login/Login';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import Files from './components/Pages/Files/File';
import Layout from './Layout';
import TotalAssignedFIles from './components/Pages/Dashboard/sub-Pages/TotalAssignedFIles';
import QueryRaisedFiles from './components/Pages/Dashboard/sub-Pages/QueryRaisedFiles';
import PendingAdvFiles from './components/Pages/Dashboard/sub-Pages/PendingAdvFiles';
import CompletedFiles from './components/Pages/Dashboard/sub-Pages/CompletedFiles';
import PendindQuery from './components/Pages/PendingQuery/PendingQuery';
import EodReport from './components/Pages/Reports/EodReport';
import MisReport from './components/Pages/Reports/MisReport';
import FileDashboard from './components/Pages/FileDashboard/FileDashboard';
import AddUser from './components/Pages/UserManagement/AddUser';
import FilesSubReport from './components/Pages/Files/FilesSubReport';
import PendingQuerySubreport from './components/Pages/PendingQuery/PendingQuerySubreport';
import EodSubReport from './components/Pages/Reports/EodSubReport';
import MisSubReport from './components/Pages/Reports/MisSubReport';
import ForgetPassword from './components/Login/ForgetPassword';
import Searchreport from './components/Layout/Searchreport';
import Loader from './utils/Loader';
function App() {
    const [loading, setLoading] = useState(false);


    // useEffect(() => {

    //     setInterval(() => {
    //         const currentTime = Date.now();
    //         console.log("currentTime", currentTime);
    //         const localStoredFileTime = localStorage.getItem("filesloadtime");
    //         const localStoredFile = localStorage.getItem("filepagelocalstorage");
    //         const localStoredpendingqueryTime = localStorage.getItem("pendingqueryloadtime");
    //         const localStoredpendingquery = localStorage.getItem("pendingquerylocalstorage");
    //         const localStoredmisTime = localStorage.getItem("misloadtime");
    //         const localStoredmis = localStorage.getItem("misreportlocalstorage");
    //         const localStoredeodTime = localStorage.getItem("eodloadtime");
    //         const localStoredeod = localStorage.getItem("eodreportlocalstorage");

    //         if (localStoredFileTime && localStoredFile) {
    //             const fileLoadTime = parseInt(localStoredFileTime, 10);
    //             const timeDifference = currentTime - fileLoadTime;
    //             console.log("filesloadtime", localStoredFileTime);
    //             console.log("timeDifference1", timeDifference);

    //             if (timeDifference >= 600000) { // 10 minutes in milliseconds
    //                 localStorage.removeItem("filepagelocalstorage");
    //                 localStorage.removeItem("filesloadtime");
    //                 console.log("filepagelocalstorage cleared");
    //             }
    //         }
    //         if (localStoredpendingqueryTime && localStoredpendingquery) {
    //             const pendingqueryfileLoadTime = parseInt(localStoredpendingqueryTime, 10);
    //             const timeDifference = currentTime - pendingqueryfileLoadTime;
    //             console.log("filesloadtime", localStoredpendingqueryTime);
    //             console.log("timeDifferencepending", timeDifference);

    //             if (timeDifference >= 600000) { // 10 minutes in milliseconds
    //                 localStorage.removeItem("pendingquerylocalstorage");
    //                 localStorage.removeItem("pendingqueryloadtime");
    //                 console.log("pendingquerylocalstorage cleared");
    //             }
    //         }
    //         if (localStoredmisTime && localStoredmis) {
    //             const misfileLoadTime = parseInt(localStoredmisTime, 10);
    //             const timeDifference = currentTime - misfileLoadTime;
    //             console.log("misfileLoadTime", localStoredmisTime);
    //             console.log("timeDifferencemis", timeDifference);

    //             if (timeDifference >= 600000) { // 10 minutes in milliseconds
    //                 localStorage.removeItem("misreportlocalstorage");
    //                 localStorage.removeItem("misloadtime");
    //                 console.log("misreportlocalstorage cleared");
    //             }
    //         }
    //         if (localStoredeodTime && localStoredeod) {
    //             const eodfileLoadTime = parseInt(localStoredeodTime, 10);
    //             const timeDifference = currentTime - eodfileLoadTime;
    //             console.log("filesloadtime", localStoredeodTime);
    //             console.log("timeDifference", timeDifference);

    //             if (timeDifference >= 600000) { // 10 minutes in milliseconds
    //                 localStorage.removeItem("eodreportlocalstorage");
    //                 localStorage.removeItem("eodloadtime");
    //                 console.log("eodreportlocalstorage cleared");
    //             }
    //         }
    //     }, 300000); // Check every 5 minutes (300000 milliseconds)

    //     // setTimeout(() => {
    //     //     localStorage.removeItem("misreportlocalstorage");
    //     //     localStorage.removeItem("eodreportlocalstorage");
    //     //     localStorage.removeItem("pendingquerylocalstorage");
    //     //     localStorage.removeItem("filepagelocalstorage");
    //     //     console.log('localStorage cleared after 10 minutes');
    //     //   }, 180000);

    //     const clearStorage = () => {
    //         // localStorage.clear(); // Clear local storage
    //         // sessionStorage.clear(); // Clear session storage
    //     };

    //     // Add event listener for beforeunload
    //     window.addEventListener('beforeunload', clearStorage);

    //     return () => {
    //         // Remove event listener when component unmounts
    //         window.removeEventListener('beforeunload', clearStorage);
    //     };
    // }, []);
    return (
        <BrowserRouter>
            {loading && <Loader />}
            <Routes>
                <Route path="/" element={<LoginForm />} />
                <Route path="/forgot-password" element={<ForgetPassword />} />
                <Route path="/layout/*" element={<Layout />}>
                    <Route index path="dashboard" element={<Dashboard setLoading={setLoading} />} />
                    <Route path="total-assigned-files/:status" element={<TotalAssignedFIles setLoading={setLoading} />} />
                    <Route path="query-raised-files/:status" element={<QueryRaisedFiles setLoading={setLoading} />} />
                    <Route path="pending-adv-files/:status" element={<PendingAdvFiles setLoading={setLoading} />} />
                    <Route path="completed-files/:status" element={<CompletedFiles setLoading={setLoading} />} />
                    <Route path="files" element={<Files setLoading={setLoading} />} />
                    <Route path="file-sub-report" element={<FilesSubReport setLoading={setLoading}/>} />
                    <Route path="pending-query" element={<PendindQuery setLoading={setLoading} />} />
                    <Route path="pending-query-sub-report" element={<PendingQuerySubreport setLoading={setLoading} />} />
                    <Route path="eod-report" element={<EodReport setLoading={setLoading} />} />
                    <Route path="eod-sub-report" element={<EodSubReport setLoading={setLoading}  />} />
                    <Route path="mis-report" element={<MisReport setLoading={setLoading} />} />
                    <Route path="mis-sub-report" element={<MisSubReport setLoading={setLoading} />} />
                    <Route path="filedash/:id" element={<FileDashboard setLoading={setLoading} />} />
                    <Route path="adduser" element={<AddUser />} />
                    <Route path="searchreport" element={<Searchreport />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default App;