import React, { useEffect, useState } from 'react';
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import pageleft from "../../../assets/dashboard/pagination-left.png";
import pageright from "../../../assets/dashboard/pagination-right.png";
import '../Dashboard/sub-Pages/index.css';
import ReactPaginate from 'react-paginate';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../../Layout/Header';
import * as XLSX from 'xlsx';
import { getFileStatus } from "../../../utils/getFileStatus";
import ExcelFiles from "./ExcelFiles";
import { callApi } from '../../../utils/Api';

interface FileData {
         request:any
}
interface DashboardProps {
    setLoading: (loading: boolean) => void;
  }

const FilesSubReport: React.FC<DashboardProps> = ({ setLoading }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const navigationData = location.state?.request;
    console.log("navigationData ____________", navigationData);
    const [files, setFiles] = useState<FileData[]>([]);
    const [limitList, setLimitList] = useState(['5', '10', '15', '20', 'All']);
    const [selectLimit, setSelectLimit] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedlimit, setSelectedlimit] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [dates, setDates] = useState("");
    const [branch, setBranch] = useState("");
    const [currentfilesDetails, setCurrentFilesDetails] = useState<
    { total: any; status: any }[]
  >([]);
  const [totalFileCount, setTotalFileCount] = useState(0);

    useEffect(() => {
        // setFiles(navigationData.data);
        setDates(navigationData.date);
        setBranch(navigationData.branch);

        console.log("FilesSubReport Files---", files);
    }, [navigationData]);

    useEffect(() => {
        console.log("updated FilesSubReport Files---", files);
    }, [files, itemsPerPage]);
    useEffect(()=>{
        getCurrentFilesDetails(1)
    },[])

    const getCurrentFilesDetails = (pagecount: any) => {
        setCurrentFilesDetails([]);
        // const { request } = navigationData;
        // console.log("jjjdsugey====================>",request);
        
        navigationData.page_id = pagecount;
        setLoading(true);
        callApi("POST", "bank_url_total_files_new.php", navigationData)
      .then((res) => res.data)
      .then((result) => {
        setCurrentFilesDetails(result.data);
        localStorage.setItem("filepagelocalstorage", JSON.stringify(result.data));
        setTotalFileCount(result.count);
        setCurrentPage(prevPage => {
          const newPage = prevPage;
          return newPage;
        });})
        setLoading(false)
    }


    const handlepageListChange = (event: any) => {
        if (event.target.value == "All") {
          setSelectedlimit(event.target.value);
          setSelectLimit(event.target.value);
          setItemsPerPage(totalFileCount);
        } else {
          setSelectLimit(event.target.value);
          setItemsPerPage(event.target.value);
        }
      };
    
      const handlePageClickprevious = () => {
        // setCurrentPage(currentPage - 1);
        setCurrentPage(prevPage => {
          const newPage = prevPage - 1;
          getCurrentFilesDetails(newPage);
    
          return newPage;
        });
        // getCurrentFilesDetails(pageCount);
      };
      const handlePageClicknext = () => {
        // setCurrentPage(currentPage + 1);
        setCurrentPage(prevPage => {
          const newPage = prevPage + 1;
          getCurrentFilesDetails(newPage);
    
          return newPage;
        });
      };
    
      const pageCount = Math.ceil(totalFileCount / itemsPerPage);
    
      const indexOfLastItem = (currentPage) * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = currentfilesDetails
    
    const routeToDashboard = (id: any) => {
        navigate(`/layout/filedash/${id}`);
    }

    const downloadExcel = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        const flattenedData = files.map((file: any, index: any) => {
            // const branch = file.branch.join('\n');
            const status = getFileStatus(file?.status);
            return {
                ID: file.s_no,
                "Borrower Name": file.property_owner_name,
                "Application ID": file.app_id,
                "File Name": file.file_name,
                // Branch: branch,
                "Status": status
            };
        });
        const headers = [
            'ID',
            'File Name',
            'Application ID',
            'Borrower Name',
            // 'Branch',
            'Status'
        ];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, { header: headers });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, 'Files');
        const fileName = `Files ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    return (
        <>
            <Header title="Files" location="sub" path="file" />
            <div className="container mt-5 mobile-view-file-table">
                <div className="card">
                    <div className="row table-main-header">
                        <div className="col-md-6 pl-3">
                            {/* <p className='branch-name'>{branch} Branch</p> */}
                            {/* <p className='filtered-date'>{dates}</p> */}
                            <p className='filtered-date'>{navigationData.fromdate} {navigationData.fromdate ? "To" : ""} {navigationData.todate}</p>
                        </div>
                        <div className="col-md-6 pr-3">
                            {/* <button className='download-btn' onClick={downloadExcel}>
                                <img className='download-image' src={excelDownloadIcon} alt="" />
                                Download
                            </button> */}
                            <ExcelFiles data={files} />
                        </div>
                    </div>
                    <div className="table-container pl-3 pr-3">
                        <table>
                            <thead>
                                <tr className='head-row'>
                                    <td>S.No</td>
                                    <td>Name</td>
                                    <td>Application No</td>
                                    <td>LH File No</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.length > 0 ? currentItems.map((file: any, index: any) => {
                                    return (
                                        <tr className='body-row' key={index} onClick={() => routeToDashboard(file.id)}>
                                            <td>{file.s_no}</td>
                                            <td style={{ width: "25%" }}>{file.property_owner_name}</td>
                                            <td><div className='app-no mt-3'><p>{file.app_id ? file.app_id : "N/A"}</p></div></td>
                                            <td>{file.file_name}</td>
                                            <td>
                                                {getFileStatus(file?.status)}
                                            </td>
                                        </tr>
                                    );
                                }) : <><tr><td colSpan={4} style={{ textAlign: "center", justifyItems: "center", color: "gray", fontSize: "20px" }}>No data available</td></tr></>}
                            </tbody>
                        </table>
                        <div className="pagination-and-info">
                            {/* Pagination */}
                            <div className="pagination">
                                <button onClick={handlePageClickprevious} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === 1}>
                                    <img
                                        src={pageleft}
                                        alt="left arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px", backgroundColor: "white", margin: "5px 0px 0px 5px", display: 'flex' }}>
                                    {pageCount === 0 ? "0" : currentPage}
                                    <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                                    {pageCount}
                                </span>
                                <button onClick={handlePageClicknext} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === pageCount}>
                                    <img
                                        src={pageright}
                                        alt="right arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                            </div>
                            <div className='pagination'>
                                <span style={{ marginRight: "10px", marginTop: "15px" }}>Total Count : {totalFileCount}</span>

                            </div>
                            {/* <div className="row-per-page mt-5">
                                <span style={{ marginRight: "10px" }}>Rows per page</span>
                                <select className='select' aria-placeholder='Choose Branch' value={selectLimit} onChange={handlepageListChange} style={{ height: "30px", width: "48px", padding: "0px 2px 0px" }}>
                                    {limitList.map((limitValue, index) => (
                                        <option key={index} value={limitValue}>
                                            {limitValue}
                                        </option>
                                    ))}
                                </select>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default FilesSubReport;
