import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Dashboard/Dashboard.css";
import pageleft from "../../../assets/dashboard/pagination-left.png";
import pageright from "../../../assets/dashboard/pagination-right.png";
import calendarIcon from "../../../assets/dashboard/calendar-icon.png";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import Header from "../../Layout/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";
import "select2";
import "select2/dist/js/select2";
import "select2/dist/css/select2.min.css";
import { isEmpty } from "lodash";
import { callApi } from "../../../utils/Api";
import { showError } from "../../../utils/sweetAlert";
import Select from "react-select";
import * as XLSX from "xlsx";
import ExcelMisReport from "./ExcelMisReport";
interface DashboardProps {
  setLoading: (loading: boolean) => void;
}

const MisReport: React.FC<DashboardProps> = ({ setLoading }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [appid, setAppid] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [showToDateCalendar, setShowToDateCalendar] = useState(false);
  const [showFromDateCalendar, setShowFromDateCalendar] = useState(false);
  const toDateRef = useRef<HTMLDivElement | null>(null);
  const fromDateRef = useRef<HTMLDivElement | null>(null);
  const [bankList, setBankList] = useState([]);
  const [filteredBankList, setFilteredBankList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const selectRef = useRef<HTMLSelectElement>(null);
  const selectBranchRef = useRef<HTMLSelectElement>(null);
  let select2Instance: any = null;
  const [bankBranchList, setBankBranchList] = useState([]);
  const [filterBankBranchList, setFilterBankBranchList] = useState([]);
  const [selectedBranchItems, setSelectedBranchItems] = useState([]);
  const [filesDetails, setFilesDetails] = useState<
    { total: any; status: any }[]
  >([]);
  const [currentfilesDetails, setCurrentFilesDetails] = useState<
    { total: any; status: any }[]
  >([]);
  const [isNotValid, setIsNotValid] = useState(false);
  const [selectBankId, setSelectBankId] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [filterBankProductList, setFilterBankProductList] = useState([]);
  const [selectedProduct, setselectedProduct] = useState("");
  const [bankProductList, setBankProductList] = useState([]);
  const [filterBranchList, setFilterBranchList] = useState([]);
  const [limitList, setLimitList] = useState(["5", "10", "15", "20", "All"]);
  const [selectLimit, setSelectLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedlimit, setSelectedlimit] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalFileCount, setTotalFileCount] = useState(0);

  const storedUserDetails: any = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const selectedBanksIds = userDetails.bank_id;
  const selectedProductId = userDetails.selected_product_id;
  const selectedBranchsIds = userDetails.bank_branch_id;
  const user_type = userDetails.user_type;

  useEffect(() => {
    getBankBranches();
    getBanks();
    getBankproduct();
    getCurrentFilesDetails(1);
  }, []);

  useEffect(() => {
    console.log("currentPage________", currentPage);
  }, [currentPage]);

  useEffect(() => {
    console.log("currentfilesDetails________", currentfilesDetails);
  }, [currentfilesDetails]);

  const getBankproduct = () => {
    callApi("GET", "bank_product_get.php")
      .then((res) => res.data)
      .then((result) => {
        const data = result.data;
        if (user_type == "0") {
          setBankProductList(data);
        } else {
          const filteredproduct = data.filter((bank: any) =>
            selectedProductId.includes(bank.id)
          );
          setBankProductList(filteredproduct);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBanks = () => {
    callApi("GET", "bank_get.php")
      .then((res: any) => res.data)
      .then(async (result: any) => {
        const data = result.data;
        if (user_type == "0") {
          setBankList(data);
        } else {
          const filteredBanks = data.filter((bank: any) =>
            selectedBanksIds.includes(bank.id)
          );
          setBankList(filteredBanks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankBranches = () => {
    callApi("GET", "bank_branch_get.php")
      .then((res) => res.data)
      .then((result) => {
        const data = result.data;
        if (user_type == "0") {
          setBankBranchList(data);
          console.log("user_type______0");
        } else {
          const filteredBanks = data.filter((bank: any) =>
            selectedBranchsIds.includes(bank.id)
          );
          setBankBranchList(filteredBanks);
          console.log("user_type______1", selectedBranchsIds, filteredBanks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("selectBankId____", selectBankId, selectedBranch);
  }, [
    filteredBankList,
    selectBankId,
    bankList,
    bankBranchList,
    branchList,
    selectedBranch,
  ]);

  useEffect(() => { }, [filteredBankList, bankList, bankBranchList]);

  const handleAppId = (event: any) => {
    setAppid(event.target.value);
  };

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const parseFormattedDate = (formattedDate: string): Date => {
    const parts = formattedDate.split("-");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1;
    const year = parseInt(parts[2]);
    return new Date(year, month, day);
  };

  const handleFromDateChange = (date: Date | null) => {
    if (date) {
      const selectedFromDate = date;
      const formattedDate = formatDate(selectedFromDate);
      if (!toDate || parseFormattedDate(fromDate) >= selectedFromDate) {
        setFromDate(formattedDate);
        setShowFromDateCalendar(false);
        console.log("fromdate", formattedDate);
      } else {
        showError(
          "Failed!",
          "Selected StartDate must be less than or equal to EndDate"
        );
        console.error("Selected fromDate must be less than or equal to toDate");
      }
    }
  };

  const handleToDateChange = (date: Date | null) => {
    if (date) {
      const selectedToDate = date;
      const formattedDate = formatDate(selectedToDate);
      if (!fromDate || parseFormattedDate(fromDate) <= selectedToDate) {
        setToDate(formattedDate);
        setShowToDateCalendar(false);
        console.log("todate", formattedDate);
      } else {
        showError(
          "Failed!",
          "Selected EndDate must be greater than or equal to StartDate"
        );
        console.error(
          "Selected toDate must be greater than or equal to fromDate"
        );
      }
    }
  };

  const handleBankChange = (e: any, type: any) => {
    const { name, id } = e;
    let selectedBankIds: any = [];
    selectedBankIds.push(id);
    filterProduct(id);
    filterBranches(id);
    if (id == "all" && user_type == "1") {
      setSelectBankId(selectedBanksIds);
    } else if (id == "all" && user_type == "0") {
      setSelectBankId([]);
      setSelectedBranchItems([]);
      setFilterBranchList([]);
    } else {
      setSelectBankId(selectedBankIds);
    }
    if (user_type == "0") {
      const filteredBranches = bankBranchList.filter(
        (branch: any) => branch.bank_id === id
      );
      setFilterBankBranchList(filteredBranches);
    }
  };

  const filterProduct = (bankid: any) => {
    if (bankid) {
      const filteredProduct = bankProductList.filter(
        (branch: any) => branch.bank_id === bankid
      );
      setFilterBankProductList(filteredProduct);
    } else {
      setselectedProduct("");
    }
  };

  const handleProductChange = (e: any, type: any) => {
    const { product_id, id } = e;
    if (product_id != "all") {
      setselectedProduct(product_id);
      filterBranchesproduct(id);
    } else {
      setselectedProduct("");
    }
  };
  const filterBranches = (bankid: any) => {
    const filteredBranches = bankBranchList.filter(
      (branch: any) => branch.bank_id === bankid
    );
    setFilterBankBranchList(filteredBranches);
  };
  const filterBranchesproduct = (productid: any) => {
    let bankids: any = selectBankId;
    let bankidArray = bankids[0];
    let firstbankId = bankidArray;
    const filteredBranches = filterBankBranchList.filter((branch: any) => {
      return branch.product_id === productid && branch.bank_id === firstbankId;
    });
    setFilterBranchList(filteredBranches);
  };

  const handleBranchChange = (e: any) => {
    const { name, id } = e;
    let selectedBranchIds: any = [];
    selectedBranchIds.push(id);
    if (id == "all" && user_type == "1") {
      setSelectedBranchItems(selectedBranchsIds);
    } else if (id == "all" && user_type == "0") {
      setSelectedBranchItems([]);
    } else {
      setSelectedBranchItems(selectedBranchIds);
    }
  };

  const searchBranchStatus = () => {
    // console.log("searchBranchStatus-----", fromDate, toDate, selectedItems, selectedBranchItems, appid);
    // if (isEmpty(fromDate) || isEmpty(toDate)) {
    //     setIsNotValid(true);
    // } else {
    //     setIsNotValid(false);
    //     getFilesDetails();
    // }
    getFilesDetails();
  };

  const getFilesDetails = () => {
    const storedUserDetails: any = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(storedUserDetails);
    const userId = userDetails.userId;
    const request = {
      from_date: fromDate,
      to_date: toDate,
      bankname: selectBankId.length > 0 ? selectBankId : selectedBanksIds,
      bankbranch:
        selectedBranchItems.length > 0
          ? selectedBranchItems
          : selectedBranchsIds,
      userId: userId,
      app_id: appid,
      product_id: selectedProduct,
      page_id: pageCount,
      row_count: "20",
    };
    setLoading(true);
    // callApi("POST", "bank_url_mis_report.php", request)
    //   .then((res) => res.data)
    //   .then((result) => {
    //     const data = result;
    //     data.from_date = fromDate;
    //     data.to_date = toDate;
    navigate("/layout/mis-sub-report", { state: { request } });
    //     setLoading(false);
    //     setFilesDetails(result);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setLoading(false)
  };
  const getCurrentFilesDetails = (pagecount: any) => {
    setCurrentFilesDetails([]);
    const storedUserDetails: any = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(storedUserDetails);
    const userId = userDetails.userId;
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding padding if month is single digit
    const day = date.getDate().toString().padStart(2, "0"); // Adding padding if day is single digit
    const formattedDate = `${day}-${month}-${year}`;
    const misreportlocalgetitem: any = localStorage.getItem("misreportlocalstorage");
    // if (misreportlocalgetitem !== null && misreportlocalgetitem !== "" && misreportlocalgetitem !== undefined) {
    //   setCurrentFilesDetails(JSON.parse(misreportlocalgetitem))
    // } else {
    const request = {
      from_date: formattedDate,
      to_date: formattedDate,
      bankname: selectBankId.length > 0 ? selectBankId : selectedBanksIds,
      bankbranch:
        selectedBranchItems.length > 0
          ? selectedBranchItems
          : selectedBranchsIds,
      userId: userId,
      app_id: appid,
      product_id: selectedProduct,
      page_id: pagecount,
      row_count: "20",
    };
    setLoading(true);
    callApi("POST", "bank_url_mis_report_new.php", request)
      .then((res) => res.data)
      .then((result) => {
        setCurrentFilesDetails(result.responseJson);
        localStorage.setItem("misreportlocalstorage", JSON.stringify(result.responseJson))
        const missettime: any = Date.now()
        localStorage.setItem("misloadtime", missettime)
        console.log("misloadtime", missettime)
        setTotalFileCount(result.count);
        setCurrentPage(prevPage => {
          const newPage = prevPage;
          return newPage;
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        toDateRef.current &&
        !(toDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowToDateCalendar(false);
      }
      if (
        fromDateRef.current &&
        !(fromDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowFromDateCalendar(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handlepageListChange = (event: any) => {
    if (event.target.value == "All") {
      setSelectedlimit(event.target.value);
      setSelectLimit(event.target.value);
      setItemsPerPage(totalFileCount);
    } else {
      setSelectLimit(event.target.value);
      setItemsPerPage(event.target.value);
    }
  };

  const handlePageClickprevious = () => {
    // setCurrentPage(currentPage - 1);
    setCurrentPage(prevPage => {
      const newPage = prevPage - 1;
      getCurrentFilesDetails(newPage);

      return newPage;
    });
    // getCurrentFilesDetails(pageCount);
  };
  const handlePageClicknext = () => {
    // setCurrentPage(currentPage + 1);
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;
      getCurrentFilesDetails(newPage);

      return newPage;
    });
  };

  const pageCount = Math.ceil(totalFileCount / itemsPerPage);

  const indexOfLastItem = (currentPage) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currentfilesDetails;

  const banksoptions = [{ id: "all", name: "All" }, ...bankList];

  const filteredBranchesoptions = [
    { id: "all", name: "All" },
    ...filterBranchList,
  ];

  const filteredProduct = [
    { product_id: "all", product_name: "All" },
    ...filterBankProductList,
  ];

  let bankids: any = selectBankId;
  let bankidArray = bankids[0];
  let firstbankId = bankidArray;

  let branchids: any = selectBankId;
  let branchidArray = branchids[0];
  let firstbranchId = branchidArray;

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;

  const downloadExcel = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${currentDate.getFullYear()}`;
    const flattenedData = currentfilesDetails.map((file: any, index: any) => {
      const first_set_doc_list = file.firstset_receivedDocList
        .map(
          (doc: any, docIndex: number) => `${docIndex + 1}. ${doc.mergedValue}`
        )
        .join("\n");
      const query =
        file.query_pending && file.query_pending.length > 0
          ? file.query_pending
            .map((query: any, queryIndex: number) => `${query.mergedValue}`)
            .join("\n")
          : "Nil";
      const first_query =
        file.first_query && file.first_query.length > 0
          ? file.first_query
            .map((query: any, queryIndex: number) => `${query.mergedValue}`)
            .join("\n")
          : "Nil";
      return {
        "S.No": file.serial_no,
        "Bank Name": file.organaization_name,
        "Product": file.product_name,
        "Law Hands Branch Name": file.region_name,
        "Bank Branch Name": file.branch_name,
        "LAN No": file.Lan_no,
        "LH File No": file.LH_fileNo,
        "Customer Name": file.customer_name,
        "Property Owner Name": file.property_owner_name,
        "1st Set -Received Doc list": first_set_doc_list,
        "1st set Doc Submitted Date with Time of Login": file?.first_doc_submit_date,
        "1st-Queries": first_query,
        "Draft/preliminary report Released with Query - Date & Time": file.draft_preliminary_report,
        "Current Pending Queries": query,
        "Final Query Doc submission by Bank Date with Time": file.query_final,
        "Final Report released Date with Time": file.Final_report_released_date,
        "Final Status Queries not cleared / Final Report Pending / Report Released": file.Final_status,
      };
    });
    const headers = [
      "S.No",
      "Bank Name",
      "Product",
      "Law Hands Branch Name",
      "Bank Branch Name",
      "LAN No",
      "LH File No",
      "Customer Name",
      "Property Owner Name",
      "1st Set -Received Doc list",
      "1st set Doc Submitted Date with Time of Login",
      "1st-Queries",
      "Draft/preliminary report Released with Query - Date & Time",
      "Current Pending Queries",
      "Final Query Doc submission by Bank Date with Time",
      "Final Report released Date with Time",
      "Final Status Queries not cleared / Final Report Pending / Report Released",
    ];
    const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
      header: headers,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Files");
    const fileName = `Mis Report ${formattedDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  const routeToDashboard = (id: any) => {
    navigate(`/layout/filedash/${id}`);
  }

  return (
    <>
      <Header title="MIS Report" location="main" path="report" />
      <div className="container mobile-view-dash-filter">
        <div className="card">
          <div className="card-content">
            <div
              className="row mt-2 mobile-view-search"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="col-md-2">
                <label>Start Date</label>
                <div className="form-field" ref={fromDateRef}>
                  <input
                    type="text"
                    id="fromdate"
                    placeholder="DD-MM-YYYY"
                    value={fromDate}
                    readOnly
                    onClick={() =>
                      setShowFromDateCalendar(!showFromDateCalendar)
                    }
                  />
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    onClick={() =>
                      setShowFromDateCalendar(!showFromDateCalendar)
                    }
                  />
                  {showFromDateCalendar && (
                    <DatePicker
                      onChange={handleFromDateChange}
                      inline
                      calendarClassName="white-background"
                      popperPlacement="bottom-start"
                    />
                  )}
                </div>
                {isNotValid && isEmpty(fromDate) ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-2">
                <label>End Date</label>
                <div className="form-field" ref={toDateRef}>
                  <input
                    type="text"
                    id="todate"
                    placeholder="DD-MM-YYYY"
                    value={toDate}
                    readOnly
                    onClick={() => setShowToDateCalendar(!showToDateCalendar)}
                  />
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    onClick={() => setShowToDateCalendar(!showToDateCalendar)}
                  />
                  {showToDateCalendar && (
                    <DatePicker
                      onChange={handleToDateChange}
                      inline
                      calendarClassName="white-background"
                      popperPlacement="bottom-start"
                    />
                  )}
                </div>
                {isNotValid && isEmpty(toDate) ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-2">
                <label>Select Bank</label>
                <div className="select-field">
                  {banksoptions &&
                    banksoptions.length > 0 &&
                    banksoptions.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        options={banksoptions}
                        name="bank_id"
                        value={banksoptions.filter(
                          (option: any) => option.id === firstbankId
                        )}
                        onChange={(e: any) => handleBankChange(e, "bank")}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>Select Product</label>
                <div className="select-field">
                  {filteredProduct &&
                    filteredProduct.map((opt: any) => {
                      opt.label = opt.product_name;
                      opt.value = opt.product_id;
                    }) && (
                      <Select
                        options={filteredProduct}
                        name="product_id"
                        value={filteredProduct.find(
                          (o: any) => o.product_id === selectedProduct
                        )}
                        onChange={(e) => handleProductChange(e, "product_id")}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>Select Branch</label>
                <div className="select-field">
                  {filteredBranchesoptions &&
                    filteredBranchesoptions.length > 0 &&
                    filteredBranchesoptions.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        options={filteredBranchesoptions}
                        name="branch"
                        value={filteredBranchesoptions.find(
                          (o: any) => o.value == firstbranchId
                        )}
                        onChange={(e) => handleBranchChange(e)}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>APP ID / LH File No</label>
                <div className="form-field">
                  <input
                    type="text"
                    id="appid"
                    placeholder="APP ID / LH File No"
                    onChange={(e) => handleAppId(e)}
                    value={appid}
                  />
                </div>
              </div>
              <div className="col-md-2 formsubmit">
                <button
                  className="sumbit-btn"
                  onClick={() => searchBranchStatus()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mobile-view-file-table-mis-report">
          <div className="card card-zindex">
            <div className="row table-main-header">
              <div className="col-md-6 pl-3">
                {/* <p className="branch-name">Branch Name</p> */}
                <p className="filtered-date">
                  {formattedDate} To {formattedDate}
                </p>
              </div>
              <div className="col-md-6 pr-3">
                {/* <button className="download-btn" onClick={downloadExcel}>
                  <img
                    className="download-image"
                    src={excelDownloadIcon}
                    alt=""
                  />
                  Download
                </button> */}
                <ExcelMisReport data={currentfilesDetails} />
              </div>
            </div>
            <div className="table-container eod-table report-table pl-3 pr-3">
              <table style={{ width: "200%" }}>
                <thead>
                  <tr className="head-row">
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      Sl.No
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      Bank Name
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      Product
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      Law Hands Branch Name
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      Bank Branch Name
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      LAN No
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      LH File No
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      Customer Name
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      Property Owner Name
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      1st Set -Received Doc list
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      1st set Doc Submitted <br />
                      Date with Time Login
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      1st-Queries
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      1st Query Raised Date & Time
                    </th>
                    <th style={{ width: '100px' }} >Final Query Raised Date & Time</th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        background: "white",
                      }}
                    >
                      Current Pending Queries
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        background: "white",
                      }}
                    >
                      Final Query Doc submission
                      <br /> by Bank Date with Time
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        background: "white",
                      }}
                    >
                      Final Report released
                      <br /> Date with Time
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        background: "white",
                      }}
                    >
                      Final Status Queries <br /> not cleared / Final Report
                      Pending
                      <br /> / Report Released{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.length > 0 ? (
                    currentItems.map((file: any, index: any) => {
                      return (
                        <tr className="body-row" key={index} onClick={() => routeToDashboard(file.file_id)}>
                          <td>{file.serial_no}</td>
                          <td>{file.organaization_name}</td>
                          <td>{file.product_name}</td>
                          <td>{file.region_name}</td>
                          <td>{file.branch_name}</td>
                          <td>{file.Lan_no}</td>
                          <td>{file.LH_fileNo}</td>
                          <td>{file.customer_name}</td>
                          <td>{file.property_owner_name}</td>
                          <td>
                            {file?.firstset_receivedDocList?.length > 0 &&
                              file.firstset_receivedDocList.map(
                                (doc: any, docindex: any) => (
                                  <div
                                    style={{ textAlign: "center" }}
                                    key={docindex}
                                  >
                                    <p>
                                      {docindex + 1}. {doc.mergedValue}
                                    </p>
                                  </div>
                                )
                              )}
                          </td>
                          <td>{file?.first_doc_submit_date}</td>
                          <td>
                            {file.first_query && file.first_query.length > 0 ? (
                              file.first_query.map((list: any, index: any) => {
                                const mergedValueWithLineBreaks =
                                  list.mergedValue.replace(/\n/g, "\r\n");
                                return (
                                  <p key={index}>{mergedValueWithLineBreaks}</p>
                                );
                              })
                            ) : (
                              <p>Nil</p>
                            )}
                          </td>
                          <td>{file.querie_raised_date ?? 'Nil'}</td>
                          <td>{file.draft_preliminary_report}</td>
                          <td>
                            {file.query_pending &&
                              file.query_pending.length > 0 ? (
                              file.query_pending.map(
                                (list: any, index: any) => {
                                  const mergedValueWithLineBreaks =
                                    list.mergedValue.replace(/\n/g, "\r\n");
                                  return (
                                    <p key={index}>
                                      {mergedValueWithLineBreaks}
                                    </p>
                                  );
                                }
                              )
                            ) : (
                              <p>Nil</p>
                            )}
                          </td>
                          <td>{file.query_final ? file.query_final : "Nil"}</td>
                          <td>
                            {file.Final_report_released_date
                              ? file.Final_report_released_date
                              : "Nil"}
                          </td>
                          <td>{file.Final_status}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td
                          colSpan={10}
                          style={{
                            textAlign: "center",
                            justifyItems: "center",
                            color: "gray",
                            fontSize: "20px",
                          }}
                        >
                          No data available
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <div className="pagination-and-info">
                {/* Pagination */}
                <div className="pagination">
                  <button
                    onClick={handlePageClickprevious}
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "5px",
                      padding: "0px 0px 5px 0px",
                      backgroundColor: "white",
                    }}
                    disabled={currentPage === 1}
                  >
                    <img
                      src={pageleft}
                      alt="left arrow"
                      style={{ marginRight: "5px", width: "7px", height: "12px" }}
                    />
                  </button>
                  <span
                    style={{
                      fontSize: "18px",
                      padding: "0px 8px 8px 0px",
                      backgroundColor: "white",
                      margin: "5px 0px 0px 5px",
                      display: "flex",
                    }}
                  >
                    {pageCount === 0 ? "0" : currentPage}
                    <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                    {pageCount}
                  </span>
                  <button
                    onClick={handlePageClicknext}
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "5px",
                      padding: "0px 0px 5px 0px",
                      backgroundColor: "white",
                    }}
                    disabled={currentPage === pageCount}
                  >
                    <img
                      src={pageright}
                      alt="right arrow"
                      style={{ marginRight: "5px", width: "7px", height: "12px" }}
                    />
                  </button>
                </div>
                <div className='pagination'>
                  <span style={{ marginRight: "10px", marginTop: "15px" }}>Total Count : {totalFileCount}</span>

                </div>
                {/* <div className="row-per-page mt-5">
                  <span style={{ marginRight: "10px" }}>Rows per page</span>
                  <select
                    className="select"
                    aria-placeholder="Choose Branch"
                    value={selectLimit}
                    onChange={handlepageListChange}
                    style={{
                      height: "30px",
                      width: "48px",
                      padding: "0px 2px 0px",
                    }}
                  >
                    {limitList.map((limitValue, index) => (
                      <option key={index} value={limitValue}>
                        {limitValue}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MisReport;
