import React, { useEffect, useState } from 'react';
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import pageleft from "../../../assets/dashboard/pagination-left.png";
import pageright from "../../../assets/dashboard/pagination-right.png";
import '../Dashboard/sub-Pages/index.css';
import ReactPaginate from 'react-paginate';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../../Layout/Header';
import * as XLSX from 'xlsx';
import ExcelPendingQuery from './ExcelPendingQuery';
import { callApi } from '../../../utils/Api';

interface FileData {
   request:any
}
interface DashboardProps {
    setLoading: (loading: boolean) => void;
  }
const PendingQuerySubreport: React.FC<DashboardProps> = ({ setLoading }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const navigationData = location.state?.request;
    console.log("navigationData ____________1", navigationData);
    const [files, setFiles] = useState<FileData[]>([]);
    const [limitList, setLimitList] = useState(['5', '10', '15', '20', 'All']);
    const [selectLimit, setSelectLimit] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedlimit, setSelectedlimit] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [dates, setDates] = useState("");
    const [branch, setBranch] = useState("");
    const [currentfilesDetails, setCurrentFilesDetails] = useState<
    { total: any; status: any }[]
  >([]);
  const [totalFileCount, setTotalFileCount] = useState(0);
    useEffect(() => {
        setFiles(navigationData.data);
        setDates(navigationData.date);
        setBranch(navigationData.branch);
        console.log("PendingQuerySubreport Files---", files);
    }, [navigationData]);

    useEffect(() => {
        console.log("updated PendingQuerySubreport Files---", files);
    }, [files, itemsPerPage]);
    useEffect(()=>{
        getCurrentFilesDetails(1)
    },[])
    const getCurrentFilesDetails = (pagecount: any) => {
        setCurrentFilesDetails([]);
        navigationData.page_id = pagecount;
        setLoading(true)
        callApi("POST", "bank_url_pending_query_file_new.php", navigationData)
      .then((res) => res.data)
      .then((result) => {
        setCurrentFilesDetails(result.data);
        localStorage.setItem("filepagelocalstorage", JSON.stringify(result.data));
        setTotalFileCount(result.count);
        setCurrentPage(prevPage => {
          const newPage = prevPage;
          return newPage;
        });})
        setLoading(false)
    }
    const handlepageListChange = (event: any) => {
        if (event.target.value == "All") {
          setSelectedlimit(event.target.value);
          setSelectLimit(event.target.value);
          setItemsPerPage(totalFileCount);
        } else {
          setSelectLimit(event.target.value);
          setItemsPerPage(event.target.value);
        }
      };
    
      const handlePageClickprevious = () => {
        // setCurrentPage(currentPage - 1);
        setCurrentPage(prevPage => {
          const newPage = prevPage - 1;
          getCurrentFilesDetails(newPage);
    
          return newPage;
        });
        // getCurrentFilesDetails(pageCount);
      };
      const handlePageClicknext = () => {
        // setCurrentPage(currentPage + 1);
        setCurrentPage(prevPage => {
          const newPage = prevPage + 1;
          getCurrentFilesDetails(newPage);
    
          return newPage;
        });
      };
    
      const pageCount = Math.ceil(totalFileCount / itemsPerPage);
    
      const indexOfLastItem = (currentPage) * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = currentfilesDetails

    const routeToDashboard = (id: any) => {
        navigate(`/layout/filedash/${id}`);
    }

    const downloadExcel = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        const flattenedData = files.map((file: any, index: any) => {
            const queries = file.query.map((queryObj: any, queryIndex: number) => `${queryIndex + 1}. ${queryObj.query}`).join('\n');
            // const branch = file.branch.join('\n');
            return {
                ID: file.s_no,
                "Name": file.property_owner_name,
                "File Name": file.file_name,
                "Application ID": file.application_no,
                Query: queries,
            };
        });
        const headers = [
            'ID',
            'Name',
            'File Name',
            'Application ID',
            'Query',
        ];
        // const worksheet = XLSX.utils.json_to_sheet(flattenedData, { header: headers });
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData, { header: headers });
        // Apply styles
        const rangeString = worksheet['!ref'];
        if (rangeString) {
            const range = XLSX.utils.decode_range(rangeString);
            for (let R = range.s.r; R <= range.e.r; ++R) {
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    const cell_address = XLSX.utils.encode_cell({ r: R, c: C });
                    if (!worksheet[cell_address]) continue;
                    worksheet[cell_address].s = {
                        alignment: {
                            vertical: "center",
                            horizontal: "center",
                            wrapText: true
                        }
                    };
                }
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, 'Files');
        const fileName = `Pending Query ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    return (
        <>
            <Header title="Pending Queries" location="sub" path="pendingquery" />
            <div className="container mt-5 mobile-view-file-table">
                <div className="card">
                    <div className="row table-main-header">
                        <div className="col-md-6 pl-3">
                            {/* <p className='branch-name'>{branch} Branch</p> */}
                            {/* <p className='filtered-date'>{dates}</p> */}
                            <p className='filtered-date'>{navigationData.fromdate} {navigationData.fromdate ? "To" : ""} {navigationData.todate}</p>
                        </div>
                        <div className="col-md-6 pr-3">
                            {/* <button className='download-btn' onClick={downloadExcel}>
                                <img className='download-image' src={excelDownloadIcon} alt="" />
                                Download
                            </button> */}
                            <ExcelPendingQuery data={files} />
                        </div>
                    </div>
                    <div className="table-container pl-3 pr-3">
                        <table>
                            <thead>
                                <tr className='head-row'>
                                    <td>S.No</td>
                                    <td>Name</td>
                                    <td>LH File No</td>
                                    <td>Application No</td>
                                    <td>Queries</td>
                                    <td>Querie Raised Date & Time</td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.length > 0 ? currentItems.map((file: any, index: any) => {
                                    return (
                                        <tr className='body-row' key={index} onClick={() => routeToDashboard(file.id)}>
                                            <td style={{ width: "10%" }}>{file.s_no}</td>
                                            <td style={{ width: "15%" }}>
                                                {file.property_owner_name}
                                            </td>
                                            <td style={{ width: "10%" }}>{file.file_name}</td>
                                            <td style={{ width: "15%" }}><div className='app-no mt-3'><p>{file.application_no ? file.application_no : "N/A"}</p></div></td>
                                            <td style={{ width: "50%", textAlign: "left", paddingLeft: "5%" }}>
                                                {file.query?.length > 0 && file.query.map((data: any, queryIndex: any) => (
                                                    <>
                                                        <span key={queryIndex}>{queryIndex + 1 + ". "}{data.query}</span > <br />
                                                    </>
                                                ))}
                                            </td>
                                            <td style={{ width: "10%" }}>{file.query_raised_dt}</td>
                                        </tr>
                                    );
                                }) : <><tr><td colSpan={4} style={{ textAlign: "center", justifyItems: "center", color: "gray", fontSize: "20px" }}>No data available</td></tr></>}
                            </tbody>
                        </table>
                        <div className="pagination-and-info">
                            {/* Pagination */}
                            <div className="pagination">
                                <button onClick={handlePageClickprevious} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === 1}>
                                    <img
                                        src={pageleft}
                                        alt="left arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px", backgroundColor: "white", margin: "5px 0px 0px 5px", display: 'flex' }}>
                                    {pageCount === 0 ? "0" : currentPage}
                                    <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                                    {pageCount}
                                </span>
                                <button onClick={handlePageClicknext} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === pageCount}>
                                    <img
                                        src={pageright}
                                        alt="right arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                            </div>
                            <div className='pagination'>
                                <span style={{ marginRight: "10px", marginTop: "15px" }}>Total Count : {totalFileCount}</span>

                            </div>
                            {/* <div className="row-per-page mt-5">
                                <span style={{ marginRight: "10px" }}>Rows per page</span>
                                <select className='select' aria-placeholder='Choose Branch' value={selectLimit} onChange={handlepageListChange} style={{ height: "30px", width: "48px", padding: "0px 2px 0px" }}>
                                    {limitList.map((limitValue, index) => (
                                        <option key={index} value={limitValue}>
                                            {limitValue}
                                        </option>
                                    ))}
                                </select>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div >
        </>

    );
};
export default PendingQuerySubreport;
